import {ViewerDataTemplateComponent} from "../../../Template/viewer/Template/component/ViewerDataTemplateComponent";
import React, {useState} from "react";
import {Base3dViewerManager} from "../../../Template/viewer/Template/component/Base3dViewerManager";
import {dataService} from "../../../Template/viewer/api/dataService";
import {SaaSCavingViewerManager} from "../BminingSaasCaving/SaaSCavingViewerManager";
import {IApiDataTopographyEntity} from "../../../Template/viewer/api/IApiDataTopographyEntity";
import {TopographyEntity} from "../../../Template/viewer/Template/entities/TopographyEntity";
import Box from "@mui/material/Box";
import {Intersection, Object3D} from "three";
import {useParams} from "react-router-dom";
import {LocalProgressComponent} from "../../global/globalProgress/localProgressComponent";
import {Stack, Typography} from "@mui/material";



export const PhaseDemoPowerBiComponent = () => {
    const [manager] = useState(() => new Base3dViewerManager())
    const {phases} = useParams()

    const [isLoadingScene, setIsLoadingScene] = useState(true)


    const onInitCallback = async () => {
        dataService.registerEntityType("Phase Demo", (data, currentManager) => {
            const phaseDemoEntity = new PhaseDemoEntity(data as IApiDataPhaseDemoEntity, currentManager as SaaSCavingViewerManager);
            return Promise.resolve(phaseDemoEntity);
        })


        const url = "https://storage.googleapis.com/global-info/bmining/PhaseDemo/Scene.zip";
        const scene = await dataService.readSceneryByUrl(url, manager)
        for (const sceneEntity of scene.entities) {
            if (phases && phases.toLowerCase().includes(sceneEntity.name.toLowerCase()))
                manager.addEntity(sceneEntity)
        }
        setIsLoadingScene(false)
    }


    return <Stack>
        <Box component="div" height='100vh' width='100hw' >
            {isLoadingScene && <LocalProgressComponent/>}
            <ViewerDataTemplateComponent manager={manager} viewerInitCallback={onInitCallback}/>
        </Box>
    </Stack>

}


interface IApiDataPhaseDemoEntity extends IApiDataTopographyEntity {
    cut: number,
    cus: number,
    tonnage: number
}


class PhaseDemoEntity extends TopographyEntity {

    constructor(data: IApiDataPhaseDemoEntity, manager: Base3dViewerManager) {
        super(data, manager)
        let tooltip = `<strong>Fase ${data.name}</strong>`
        tooltip += `<br>Cut ${data.cut.toLocaleString('en', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        })} %`

        tooltip += `<br>Tonelaje ${(data.tonnage / 1e6).toLocaleString('en', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        })}`

        this.tooltip = tooltip
        this.hasTooltip = true
    }

    onMouseOver(ray: Intersection<Object3D>) {
        super.onMouseOver(ray);
    }
}