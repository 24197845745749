import {BaseEntity} from "./BaseEntity";
import {BufferGeometry, Intersection, Line, LineBasicMaterial, Object3D, Vector3} from "three";
import {Base3dViewerManager} from "../component/Base3dViewerManager";
import {IApiDataTechnicalNotesPolylineEntity} from "../../api/IApiDataTechnicalNotesPolylineEntity";

export class PolylinesTechnicalNotesEntity extends BaseEntity<Line> {

    data: IApiDataTechnicalNotesPolylineEntity

    constructor(data: IApiDataTechnicalNotesPolylineEntity, manager: Base3dViewerManager) {
        const translation = manager.getTranslation();
        const vertices: Vector3[] = []
        const level = data.level
        for (let i = 0; i < data.polylineVertices.length; i++) {
            const vertex = new Vector3(data.polylineVertices[i].x + translation.x,
                data.polylineVertices[i].y + translation.y,
                level + translation.z)
            vertices.push(vertex)
        }
        const lineGeometry = new BufferGeometry().setFromPoints(vertices)
        const lineBasicMaterial = new LineBasicMaterial({
            color: "red"
        })
        const line = new Line(lineGeometry, lineBasicMaterial)

        line.translateX(-translation.x)
        line.translateY(-translation.y)
        line.translateZ(-translation.z)

        super(line, data, manager);
        this.data = data
    }

    onMouseOver(ray: Intersection<Object3D>) {
        super.onMouseOver(ray);
        let content = `<strong>Sector ${this.data.sector}</strong>`
        content += `<br>Tonelaje ${this.data.tonnage / 1e6} Mt`
        content += `<br>Área ${(this.data.areaSquaredMeters / 1e4).toLocaleString("en", {maximumFractionDigits: 2})} ha`
        content += `<br>Cut ${this.data.averageGradePercentage.toLocaleString("en", {maximumFractionDigits: 2})} %`
        this.manager.getTooltipManager().setHtmlContent(content)
    }

    onMouseMove(ray: Intersection<Object3D>) {
        super.onMouseMove(ray);
    }
}

