import {Box, Typography} from "@mui/material";
import React, {FC, useEffect, useRef, useState} from "react";
import {Base3dViewerManager} from "./Base3dViewerManager";
import {v4 as uuidv4} from 'uuid';
import {uint} from "three/examples/jsm/nodes/shadernode/ShaderNode";
import {useOnScreen} from "../../../../hooks/useOnScreen";
import useScreenSize from "../../../../hooks/useScreenSize";

interface ViewerDataTemplateComponentProps {
    manager: Base3dViewerManager,
    viewerInitCallback?: () => Promise<void>
}


export const ViewerDataTemplateComponent: FC<ViewerDataTemplateComponentProps> = (props) => {

    const [isMounted, setIsMounted] = useState<boolean>(false)

    const ref = useRef<HTMLDivElement>(null)
    const isVisible = useOnScreen(ref)


    useEffect(() => {
        if (isVisible)
            props.manager.renderer.setPixelRatio(window.devicePixelRatio)
        else
            props.manager.renderer.setPixelRatio(0.1)
    }, [isVisible]);

    const guid = uuidv4()


    useEffect(() => {
        if (isMounted)
            return
        const appElement = document.getElementById(`id ${guid}`)!
        const cubeElement = document.getElementById(`cube-scene ${guid}`)!
        const tooltipElement = document.getElementById(`tooltip ${guid}`)!
        const intersectionTooltipElement = document.getElementById(`intersectionTooltip ${guid}`)!

        if (!appElement || !cubeElement || !tooltipElement || !intersectionTooltipElement)
            return
        props.manager.init(appElement, cubeElement, tooltipElement, intersectionTooltipElement)

        if (props.viewerInitCallback)
            props.viewerInitCallback().then(value => setIsMounted(true))


        return () => {
            props.manager.renderer.forceContextLoss()
            props.manager.renderer.dispose()
            appElement.remove()
            tooltipElement.remove()
            intersectionTooltipElement.remove()
            cubeElement.remove()
        }
    }, [])
    return <>
        <Box component="div" id={`id ${guid}`} ref={ref} height='100%' width='100%' style={{position: 'relative'
        }}>
            <Typography id={`intersectionTooltip ${guid}`} style={{
                position: "absolute", bottom: '4px',left:'4px',pointerEvents:'none',userSelect:'none'
            }} variant={'caption'}/>
        </Box>
        <div id={`cube-scene ${guid}`} style={{position: "relative"}} hidden={!isMounted}/>
        <Typography id={`tooltip ${guid}`} style={{position: "fixed",pointerEvents:'none',userSelect:'none'}}/>
    </>
}