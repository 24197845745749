import gsap from "gsap";
import {useGSAP} from "@gsap/react";
import {TextPlugin} from "gsap/TextPlugin";
import React, {useEffect, useState} from "react";
import {useIsDesktop} from "../../hooks/useIsDesktop";
import {useTheme} from "@mui/material/styles";
import {
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    Grid,
    Link,
    Slide,
    Stack,
    Typography, TypographyProps
} from "@mui/material";
import {margin, padding, spacing} from "../../Resources/styles";
import Box from "@mui/material/Box";
import * as muiImage from "mui-image";
import {BminingSaasCustomApplicationComponent} from "./BminingSaasProducts/BminingSaasCustomApplicationComponent";

gsap.registerPlugin(useGSAP)
gsap.registerPlugin(TextPlugin)


type productType = 'custom-application' | 'power-bi-3d'

interface IProduct {
    name: string,
    selected: boolean,
    type: productType,
}

const contentClass: TypographyProps = {
    textAlign: 'justify',
    component: 'span'
}

const productContentGenerator = (type: productType) => {
    switch (type) {
        case "custom-application":
            return <div>
                Creamos <strong>aplicaciones personalizadas</strong> y seguras en la nube, con autenticación de usuario
                incorporada. Estas aplicaciones proporcionan vistas interactivas en 3D, permitiendo a los usuarios
                interactuar y comprender los datos de manera efectiva. Con la facilidad de ser totalmente <strong>accesibles
                a través de Internet</strong> sin la necesidad de ninguna instalación local, nuestras aplicaciones
                pueden adaptarse para cubrir tus necesidades específicas
            </div>
        case "power-bi-3d":
            return <div>
                Ofrecemos un servicio de generación de informes en Power BI fácil de actualizar y con la capacidad de
                incorporar <strong>vistas en 3D</strong>. Este servicio convierte tus datos en información accionable,
                proporcionando análisis visualmente atractivos y altamente interactivos que se mantienen al día con
                la <strong>información más reciente</strong>.
            </div>
    }
}
const productImageGenerator = (type: productType) => {

    switch (type) {
        case "custom-application":
            return  <Box height="100%"  m={margin}>
                <BminingSaasCustomApplicationComponent/>
            </Box>

        case "power-bi-3d":
            return <muiImage.Image
                src='https://storage.googleapis.com/global-info/bmining/Power-BI.gif'
                fit='contain'/>
    }

}


export const BminingSaasHomeProductsComponent = () => {

    const isDesktop = useIsDesktop()


    const [products, setProducts] = useState<IProduct[]>([])
    const title = "Productos"

    useEffect(() => {

        setProducts([
            {
                // Añadir aca la planificación y Poner en negrita.
                selected: true,
                name: "Aplicación Personalizada",
                type: 'custom-application',
            },
            {
                selected: false,
                name: "Power BI 3D",
                type: 'power-bi-3d',
            }
        ])

    }, [])


    const theme = useTheme()

    const productSelectionCallback = (product: IProduct) => {
        const currentProducts = [...products]

        for (const currentProduct of currentProducts) {
            currentProduct.selected = false
        }
        currentProducts.find(value => value.type == product.type)!.selected = true
        setProducts(currentProducts)
    }

    if (isDesktop)

        return <Grid container spacing={spacing} direction="row">
            <Grid item xs={12} md={3} textAlign='left'>
                <Stack m={margin} p={padding} spacing={spacing} alignItems='flex-start'>
                    <Typography variant='h5'>{title}</Typography>
                    {products.map(product => {
                            return <Link key={product.name} component="button" variant="h4" textAlign='left'
                                         underline={product.selected ? "always" : "hover"}
                                         onClick={_ => {
                                             productSelectionCallback(product)
                                         }}>
                                {product.name}
                            </Link>
                        }
                    )}
                </Stack>
            </Grid>


            <Grid item xs={12} md={9}>
                <Box m={margin} p={padding}>
                    {products.map(currentProduct => {
                        return <Slide mountOnEnter unmountOnExit timeout={500} direction='left'
                                      key={currentProduct.type}
                                      in={products.find(value => value.type == currentProduct.type)?.selected}
                                      hidden={!(products.find(value => value.type == currentProduct.type)?.selected)}>
                            <Card>
                                <CardMedia
                                    component='div'
                                    sx={{height: 500, objectFit: 'cover'}}
                                    title="green iguana">
                                    {productImageGenerator(currentProduct.type)}
                                </CardMedia>
                                <CardHeader title={currentProduct.name}/>

                                <CardContent>
                                    <Typography {...contentClass}>
                                        {productContentGenerator(currentProduct.type)}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Slide>
                    })}
                </Box>
            </Grid>
        </Grid>

    return <Stack spacing={spacing}>
        <Typography variant='h5'>{title}</Typography>
        {products.map(currentProduct => {
            return <Card key={currentProduct.type}>
                <CardMedia
                    component='div'
                    sx={{height: 350}}>
                    {productImageGenerator(currentProduct.type)}
                </CardMedia>
                <CardHeader title={currentProduct.name}/>

                <CardContent>
                    <Typography {...contentClass}>
                        {productContentGenerator(currentProduct.type)}
                    </Typography>
                </CardContent>
            </Card>
        })}
    </Stack>
}