import {Button, Grid, TextField, Typography} from "@mui/material";
import {margin, padding, spacing} from "../../Resources/styles";
import React, {useState} from "react";

export const BminingSaasHomeLetsTalkComponent = () => {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [description, setDescription] = useState("")

    return <Grid m={margin} p={padding} container spacing={spacing}>
        <Grid item xs={12}>
            <Typography variant={"h5"}>
                Conversemos
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <TextField label="Nombre" value={name} onChange={event => {
                setName(event.target.value)
            }} fullWidth/>
        </Grid>
        <Grid item xs={12}>
            <TextField label="Email" value={email} onChange={event => {
                setEmail(event.target.value)
            }} fullWidth/>
        </Grid>
        <Grid item xs={12}>
            <TextField label="Descripción" value={description} onChange={event => {
                setDescription(event.target.value)
            }} fullWidth/>
        </Grid>

        <Grid item xs={12}>

            <Button variant='contained' fullWidth>
                Enviar
            </Button>
        </Grid>
    </Grid>
}