import {BaseEntity} from "./BaseEntity";
import {
    BufferGeometry, DoubleSide, Intersection, Mesh,
    MeshPhongMaterial, Object3D, Vector3
} from "three";
import {IApiDataTechnicalNotesSolidEntity} from "../../api/IApiDataTechnicalNotesSolidEntity";
import {Base3dViewerManager} from "../component/Base3dViewerManager";

export class SolidTechnicalNotesEntity extends BaseEntity<Mesh> {

    data: IApiDataTechnicalNotesSolidEntity

    constructor(data: IApiDataTechnicalNotesSolidEntity, manager: Base3dViewerManager) {
        const translation = manager.getTranslation();

        const bufferGeometry = new BufferGeometry()
        const vertices: Vector3[] = []
        for (let i = 0; i < data.solidVertices.length; i += 3) {
            const x = data.solidVertices[i] + translation.x
            const y = data.solidVertices[i + 1] + translation.y
            const z = data.solidVertices[i + 2] + translation.z
            vertices.push(new Vector3(x, y, z))
        }
        bufferGeometry.setFromPoints(vertices)
        bufferGeometry.computeVertexNormals()


        const material = new MeshPhongMaterial({
            color: "brown",
            side: DoubleSide
        })
        const mesh = new Mesh(bufferGeometry, material)

        mesh.translateX(-translation.x)
        mesh.translateY(-translation.y)
        mesh.translateZ(-translation.z)

        super(mesh, data, manager);
        this.data = data
    }

    onMouseOver(ray: Intersection<Object3D>) {
        super.onMouseOver(ray);
        let content = `<strong>Sólido ${this.data.sector}</strong>`
        content += `<br>Tonelaje ${(this.data.tonnage / 1e6).toLocaleString
        ("en", {maximumFractionDigits: 2})} Mt`
        content += `<br>Área ${this.data.areaSquaredMeters / 10000} ha`
        this.manager.getTooltipManager().setHtmlContent(content)
    }

    onMouseMove(ray: Intersection<Object3D>) {
        super.onMouseMove(ray);
    }
}