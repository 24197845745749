import {Container, Stack, Typography} from "@mui/material";
import React from "react";
import Box from "@mui/material/Box";
import {useTheme} from '@mui/material/styles';
import {BminingSaaSHomeMainBannerComponent} from "./BminingSaaSHomeMainBannerComponent";
import {BminingSaaSHomeCavingPlanningComponent} from "./BminingSaaSHomeCavingPlanningComponent";
import {useIsDesktop} from "../../hooks/useIsDesktop";
import {BminingSaasHomeProductsComponent} from "./BminingSaasHomeProductsComponent";
import {BminingSaaSHomeWhyComponent} from "./BminingSaaSHomeWhyComponent";
import {BminingSaaSHomeWorkflowComponent} from "./BminingSaaSHomeWorkflowComponent";
import {
    CavingPlanningDesktopComponent,
    CavingPlanningAccordionComponent,
    BminingSaaSCavingPlanningComponent
} from "./BminingSaasCaving/BminingSaaSCavingComponent";
import {spacing} from "../../Resources/styles";
import {BminingSaasHomeLetsTalkComponent} from "./BminingSaasHomeLetsTalkComponent";

export const BminingSaasHomeComponent = () => {
    const isDesktop = useIsDesktop()
    const theme = useTheme();

    return <Container>
        <Stack spacing={spacing}>
            <Box>
                <BminingSaaSHomeMainBannerComponent/>
            </Box>

            <Box sx={{backgroundColor: isDesktop ? "#fafafa" : "default"}}>
                <BminingSaasHomeProductsComponent/>
            </Box>

            <Box>
                {isDesktop && <CavingPlanningDesktopComponent/>}
                {!isDesktop && <CavingPlanningAccordionComponent/>}
            </Box>

            <Box sx={{backgroundColor: isDesktop ? "#fafafa" : "default"}}>
                <BminingSaaSHomeWhyComponent/>
            </Box>

            <Box>
                <BminingSaasHomeLetsTalkComponent/>
            </Box>
        </Stack>
    </Container>
}

