import * as React from "react";
import {environment} from "./api/ApiConfig";
import {BhpGeomeasurementsRoutes} from "./environment/BhpGeomeasurementsRoutes";
import {EnvironmentNames} from "./environment/EnvironmentNames";
import {DemoRoutes} from "./environment/DemoRoutes";
import Box from "@mui/material/Box";
import {margin} from "./Resources/styles";
import {Route, Routes} from "react-router-dom";
import {PhaseDemoPowerBiComponent} from "./components/BminingSaaS/PowerBI/PhaseDemoPowerBiComponent";
import {
    TechnicalNotesAlternativePowerBiComponent
} from "./components/BminingSaaS/PowerBI/TechnicalNotesAlternativePowerBiComponent";

export const RoutesComponent = () => {

    if (environment.includes("Power")) {

        return <Routes>
            <Route path='/demo/phases/:phases' element={<PhaseDemoPowerBiComponent/>}/>
            <Route path='/demo/phases' element={<PhaseDemoPowerBiComponent/>}/>
            <Route path='/demo/technical_note/ug/alternative_1' element={
                <TechnicalNotesAlternativePowerBiComponent/>
            }/>
        </Routes>
    }

    if (environment.includes(EnvironmentNames.Geomeasurement)) {
        return <Box marginTop={margin} marginBottom={margin}>
            <BhpGeomeasurementsRoutes/>
        </Box>
    }

    if (environment.includes("Demo")) {
        return <DemoRoutes/>
    }


    return <></>
}
