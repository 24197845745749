import {BaseEntity} from "./BaseEntity";
import {
    BoxGeometry,
    BufferAttribute,
    BufferGeometry,
    Color,
    Intersection,
    Mesh,
    MeshPhongMaterial,
    Object3D
} from "three";
import {IApiDataTechnicalNotesColumnsEntity} from "../../api/IApiDataTechnicalNotesColumnsEntity";
import {Base3dViewerManager} from "../component/Base3dViewerManager";
import {IApiDataTechnicalNotesColumn} from "../../api/IApiDataTechnicalNotesColumn";
import {BlockStructure} from "./BlockStructure";

export class ColumnsTechnicalNotesEntity extends BaseEntity<Mesh> {

    columnData: Array<IApiDataTechnicalNotesColumn | null> []
    structure: BlockStructure

    constructor(data: IApiDataTechnicalNotesColumnsEntity, manager: Base3dViewerManager) {

        const translation = manager.getTranslation();

        const vertices: number[] = []
        const colors: number[] = []
        const indices: number[] = []

        let currentIndex = 0
        for (const column of data.columns) {
            const boxGeometry = new BoxGeometry(1, 1, 1)

            boxGeometry.scale(18, 18, column.height)
            boxGeometry.translate(0, 0, column.height / 2)


            const verticesAttribute = boxGeometry.getAttribute("position")!
            const indicesAttribute = boxGeometry.getIndex()!

            const color = new Color(column.color)
            for (let i = 0; i < verticesAttribute.count; i++) {
                vertices.push(verticesAttribute.getX(i) + column.centroid.x + translation.x)
                vertices.push(verticesAttribute.getY(i) + column.centroid.y + translation.y)
                vertices.push(verticesAttribute.getZ(i) + column.level + translation.z)

                colors.push(color.r)
                colors.push(color.g)
                colors.push(color.b)
            }


            for (let i = 0; i < indicesAttribute.count; i++) {
                indices.push(indicesAttribute.getX(i) + currentIndex)
            }
            currentIndex += verticesAttribute.count
        }
        const geometry = new BufferGeometry()
        const material = new MeshPhongMaterial({
            vertexColors: true
        })
        geometry.setAttribute("position", new BufferAttribute(new Float32Array(vertices), 3))
        geometry.setAttribute("color", new BufferAttribute(new Float32Array(colors), 3))
        geometry.setIndex(indices)
        geometry.computeVertexNormals()

        const mesh = new Mesh(geometry, material)


        mesh.translateX(-translation.x)
        mesh.translateY(-translation.y)
        mesh.translateZ(-translation.z)
        super(mesh, data, manager);

        const minX = Math.min(...data.columns.map(value => value.centroid.x))
        const minY = Math.min(...data.columns.map(value => value.centroid.y))

        const maxX = Math.max(...data.columns.map(value => value.centroid.x))
        const maxY = Math.max(...data.columns.map(value => value.centroid.y))

        const blockSizeX = data.blockSizeX
        const blockSizeY = data.blockSizeY

        const numberOfBlocksX = Math.round((maxX - minX) / blockSizeX) + 1
        const numberOfBlocksY = Math.round((maxY - minY) / blockSizeY) + 1

        this.columnData = []
        for (let i = 0; i < numberOfBlocksX; i++) {
            const row: Array<IApiDataTechnicalNotesColumn | null> = []
            for (let j = 0; j < numberOfBlocksY; j++) {
                row.push(null)
            }
            this.columnData.push(row)
        }

        this.structure = new BlockStructure(numberOfBlocksX, numberOfBlocksY, blockSizeX, blockSizeY, minX, minY)


        for (const column of data.columns) {

            const subscriptI = this.structure.getSubscriptI(column.centroid.x)
            const subscriptJ = this.structure.getSubscriptJ(column.centroid.y)

            this.columnData[subscriptI][subscriptJ] = column
        }
    }

    onMouseOver(ray: Intersection<Object3D>) {
        super.onMouseOver(ray);
    }

    onMouseMove(ray: Intersection<Object3D>) {
        super.onMouseMove(ray);
        const i = this.structure.getSubscriptI(ray.point.x)
        const j = this.structure.getSubscriptJ(ray.point.y)

        const column = this.columnData[i][j]
        if (column == null)
            return

        let content = `<strong>Columna ${i},${j}</strong>`
        content += `<br>Altura ${column.height} m`
        content += `<br>Valor ${(column.value * 10).toLocaleString("en", {maximumFractionDigits: 2})} KUSD`

        this.manager.getTooltipManager().setHtmlContent(content)
    }
}


