export class BlockStructure {
    numberOfBlocksX: number
    numberOfBlocksY: number

    distanceBlocksX: number
    distanceBlocksY: number

    initialCentroidX: number
    initialCentroidY: number

    constructor(numberOfBlocksX: number, numberOfBlocksY: number, distanceBlocksX: number, distanceBlocksY: number,
                initialCentroidX: number, initialCentroidY: number) {
        this.numberOfBlocksX = numberOfBlocksX;
        this.numberOfBlocksY = numberOfBlocksY;
        this.distanceBlocksX = distanceBlocksX;
        this.distanceBlocksY = distanceBlocksY;
        this.initialCentroidX = initialCentroidX;
        this.initialCentroidY = initialCentroidY;
    }

    getSubscriptI(valueX: number) {
        return Math.round((valueX - this.initialCentroidX) / this.distanceBlocksX)
    }

    getSubscriptJ(valueY: number) {
        return Math.round((valueY - this.initialCentroidY) / this.distanceBlocksY)
    }


}