import React from "react";
import {useGSAP} from "@gsap/react";
import gsap from "gsap";
import {DrawSVGPlugin} from "gsap/DrawSVGPlugin";

gsap.registerPlugin(useGSAP)
gsap.registerPlugin(DrawSVGPlugin)

const colorA = "#053442"
const colorB = "#007072"
const colorC = "#D1A617"
const colorD = '#b5c3d0'
const colorE = '#CC0000'
const colorF = '#fdfdfd'
const backgroundText = '#f5f5f5'


const appShellStrokeWidth = 2;
const appShellStrokeColor = colorA;
const fontFamily = "Titillium Web"

const shellButtonClass = {
    strokeWidth: 1,
    stroke: colorD
}

const shellTitleClass = {
    fontSize: "12px",
    fontFamily: fontFamily,
    fill: colorA
}

const navBarTitle = {
    fontSize: "18px",
    fontFamily: fontFamily,
    fill: colorF
}

const menuTitleClass = {
    fontSize: "16px",
    fontFamily: fontFamily,
    fill: colorA,
    fontWeight: 'bold'
}

const menuItemClass = {
    fontSize: "16px",
    fontFamily: fontFamily,
    fill: colorA,
}

const viewTitleClass = {
    fontSize: "20px",
    fontFamily: fontFamily,
    fill: colorA,
}

const dialogTextClass = {
    fontSize: "16px",
    fontFamily: fontFamily,
    fill: colorA,
}

const dialogStrokeWidth = 2
const timePerCharacter = 0.02

export const BminingSaasCustomApplicationComponent = () => {

    useGSAP(() => {
        let tl = gsap.timeline({repeat: -1})
        const duration = 1
        // -- Shell Presentation --
        tl
            .fromTo("#shell-group", {autoAlpha: 0}, {
                duration: duration,
                autoAlpha: 1
            }).fromTo("#navbar-group", {autoAlpha: 0}, {
            duration: duration,
            autoAlpha: 1
        })
        generateDialogTimeLine("user", tl)
        tl.fromTo("#navigation-group", {autoAlpha: 0}, {
            duration: duration,
            autoAlpha: 1
        })
        generateDialogTimeLine("navigation", tl)
        tl.to("#navigation-group", {
            duration: 0.5,
            autoAlpha: 0
        })

        tl.fromTo("#navbar-view-a-group", {autoAlpha: 0}, {
            duration: 1,
            autoAlpha: 1,

        })

        tl.to("#navbar-view-a-group",  {
            duration: 0,
            autoAlpha: 1,
            delay:3

        })

        gsap.fromTo("#navbar-view-skeleton-group", {
            autoAlpha: 1
        }, {
            yoyo: true,
            duration: 1,
            autoAlpha: 0.5,
            repeat: -1,
            ease: "none"
        })

    }, [])


    return <svg width="100%" height="100%" viewBox="0 0 800 600" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="shell-group">
            <g id="shell-app-buttons">
                <path id="shell-close"
                      d="M790.5 16.0256C790.5 20.0479 787.35 23.28 783.5 23.28C779.65 23.28 776.5 20.0479 776.5 16.0256C776.5 12.0033 779.65 8.77129 783.5 8.77129C787.35 8.77129 790.5 12.0033 790.5 16.0256Z"
                      {...shellButtonClass} fill={colorE}/>
                <path id="shell-maximize"
                      d="M769 16.0256C769 20.0479 765.85 23.28 762 23.28C758.15 23.28 755 20.0479 755 16.0256C755 12.0033 758.15 8.77129 762 8.77129C765.85 8.77129 769 12.0033 769 16.0256Z"
                      {...shellButtonClass} fill={colorB}/>
                <path id="shell-minimize"
                      d="M747.5 16.0256C747.5 20.0479 744.35 23.28 740.5 23.28C736.65 23.28 733.5 20.0479 733.5 16.0256C733.5 12.0033 736.65 8.7713 740.5 8.7713C744.35 8.7713 747.5 12.0033 747.5 16.0256Z"
                      {...shellButtonClass} fill={colorC}/>
            </g>

            <text id="navigation-title" x="400" y="15"
                  dominantBaseline="middle" textAnchor="middle" {...shellTitleClass} >
                Aplicación en la Web
            </text>

            <line id="shell-divider" y1="29" x1="1" x2="799" y2="29"
                  stroke={appShellStrokeColor} strokeWidth={appShellStrokeWidth}/>
            <rect id="shell-framework" x="1.5" y="1.5" width="798" height="598" rx="13.5"
                  stroke={appShellStrokeColor} strokeWidth={appShellStrokeWidth}/>

        </g>
        <g id="navbar-group">
            <rect id="NavBar" x="1" y="29" width="798" height="38" fill={colorA}/>

            <svg x="4" y="30" width="34" height="34" fill="none" viewBox="0 0 512 512"
                 id="navbar-menu">
                <path fill={colorF} fillRule="evenodd"
                      d="M356 181H156V166H356V181zM356 256H156V241H356V256zM356.004 331H156.004V316H356.004V331z"
                      clipRule="evenodd"/>
            </svg>

            <svg x="763" y="36" width="22" height="22" viewBox="0 0 512 512"
                 id="navbar-user">
                <path fill={colorF}
                      d="M256 256c52.805 0 96-43.201 96-96s-43.195-96-96-96-96 43.201-96 96 43.195 96 96 96zm0 48c-63.598 0-192 32.402-192 96v48h384v-48c0-63.598-128.402-96-192-96z"/>
            </svg>

            <text id="navbar-app-title" x="45" y="52" {...navBarTitle}>
                Aplicación Personalizada
            </text>
        </g>


        <g id="navigation-group">
            <rect id="navigation-panel" x="1.5" y="66.5" width="184" height="533"
                  fill={colorF} stroke={colorB} strokeWidth={2}/>
            <line id="navigation-divider" y1="100" x2="185" y2="100" stroke={colorB}/>
            <text id="navigation-title" x="94" y="85"
                  dominantBaseline="middle" textAnchor="middle" {...menuTitleClass} >
                Menu
            </text>
            <g id="navigation-menu-group">
                <rect x="15" y="111" width="60" height="24" rx="2.5" strokeWidth={1.5} stroke={colorA}/>
                <text id="navigation-menu-item-1" x="20" y="125"
                      dominantBaseline="middle"  {...menuItemClass} >
                    Vista A
                </text>

                <text id="navigation-menu-item-2" x="20" y="150"
                      dominantBaseline="middle"  {...menuItemClass} >
                    Vista B
                </text>

                <text id="navigation-menu-item-3" x="20" y="175"
                      dominantBaseline="middle"  {...menuItemClass} >
                    Vista C
                </text>

                <text id="navigation-menu-item-4" x="20" y="200"
                      dominantBaseline="middle"  {...menuItemClass} >
                    Vista D
                </text>
            </g>
        </g>


        <g id="navbar-view-a-group" transform="translate(0,-25)">
            <line id="navbar-view-a-group-divider" x1="132" y1="130" x2="667" y2="130" stroke={colorA}
                  strokeWidth={1}/>
            <g id="navbar-view-skeleton-group">
                <rect x="132" y="140" width="535" height="220" rx={5} fill={colorD}/>
                <rect x="132" y="375" width="370" height="25" rx={5} fill={colorD}/>
                <rect x="132" y="410" width="450" height="25" rx={5} fill={colorD}/>
                <rect x="132" y="445" width="400" height="25" rx={5} fill={colorD}/>
            </g>
            <text x="132" y="120"  {...viewTitleClass}>Vista A</text>
        </g>


        <g transform="translate(500,50)" id="user-text-group">
            <svg width={256} height={256}>
                {userText}
            </svg>
        </g>
        <g transform="translate(774,47)" id="user-text-indicator-group">
            <circle r={17} stroke={colorF} strokeWidth={dialogStrokeWidth} id="user-text-indicator-circle"/>
        </g>


        <g transform="translate(100,50)" id="navigation-text-group">
            <svg width={256} height={256}>
                {navigationText}
            </svg>
        </g>
        <g transform="translate(21,47)" id="navigation-text-indicator-group">
            <circle r={13} stroke={colorF} strokeWidth={dialogStrokeWidth} id="navigation-text-indicator-circle"/>
        </g>
    </svg>
}


const userText = <svg viewBox="-4 -4 254 254">
    <rect id="user-rect-a" stroke={colorA} strokeWidth={dialogStrokeWidth} rx="1%" width={210} height={85}
          fill={backgroundText}/>
    <rect id="user-rect-b" stroke={colorB} strokeWidth={dialogStrokeWidth} rx="1%" width={210} height={85}/>
    <g transform="translate(10,10)">
        <text id="user-text" {...dialogTextClass}  >
            <tspan className="user-words" dy="1.2em">
                Protección de la información
            </tspan>
            <tspan className="user-words" x={0} dy="1.2em">
                mediante autenticación
            </tspan>
            <tspan className="user-words" x={0} dy="1.2em">
                y autorización de usuario
            </tspan>
        </text>
    </g>
</svg>

const navigationText = <svg viewBox="-4 -4 254 254">
    <rect id="navigation-rect-a" stroke={colorA} strokeWidth={dialogStrokeWidth} rx="1%" width={235} height={65}
          fill={backgroundText}/>
    <rect id="navigation-rect-b" stroke={colorB} strokeWidth={dialogStrokeWidth} rx="1%" width={235} height={65}/>
    <g transform="translate(10,10)">
        <text id="navigation-text" {...dialogTextClass}  >
            <tspan className="navigation-words" dy="1.2em">
                Navagación a vistas dedicadas
            </tspan>
            <tspan className="navigation-words" x={0} dy="1.2em">
                por cada función de la aplicación
            </tspan>
        </text>
    </g>
</svg>

const generateDialogTimeLine = (name: string, referenceTimeLine: gsap.core.Timeline) => {

    referenceTimeLine
        .fromTo([`#${name}-text-group`, `#${name}-text-indicator-group`], {
            autoAlpha: 0, scale: 0, transformOrigin: 'center'
        }, {autoAlpha: 1, duration: 1, scale: 1})

    let words: Element[] = gsap.utils.toArray([`.${name}-words`])
    let wordTimeline = gsap.timeline({delay: 0.5})

    words.forEach(el => {
        wordTimeline.from(el, {text: "", duration: el.innerHTML.length * timePerCharacter, ease: "none"});
    });

    referenceTimeLine.add(wordTimeline)
    // referenceTimeLine.to(["#user-text-group", "#user-text-indicator-group"], {duration: 1})
    referenceTimeLine.to([`#${name}-text-group`], {
        autoAlpha: 0,
        scale: 0,
        duration: 1,
        delay: 2
    })
    referenceTimeLine.to([`#${name}-text-indicator-group`], {
        autoAlpha: 0,
        scale: 1,
        duration: 1
    }, "<")

    //  -- Rect Animation --
    drawCircleLoading(`#${name}-text-indicator-circle`, 2)
    drawRectLoading(`#${name}-rect`, 2)
}

const drawCircleLoading = (name: string, duration: number) => {
    const tl = gsap.timeline({
        repeat: -1, defaults: {
            ease: "none",
            duration: duration
        }
    })
    tl.set(name, {drawSVG: "0% 50%"})
        .to(name, {drawSVG: "50% 100%"})
        .to(name, {drawSVG: "100% 150%"})
    tl.play()
}

const drawRectLoading = (rectName: string, duration: number) => {

    const rectsTimeline = gsap.timeline({
        repeat: -1
    })
    const groups = ["a", "b"]
    let accumulatedDuration = 0
    for (const group of groups) {
        const rectAnimation = gsap.timeline({
            repeat: -1, defaults: {
                ease: "none",
                duration: duration
            }
        })

        rectAnimation.set(`${rectName}-${group}`, {drawSVG: "0% 25%"})
            .to(`${rectName}-${group}`, {drawSVG: "25% 50%"})
            .to(`${rectName}-${group}`, {drawSVG: "50% 75%"})
            .to(`${rectName}-${group}`, {drawSVG: "75% 100%"})
            .to(`${rectName}-${group}`, {drawSVG: "100% 125%"})

        rectsTimeline.add(rectAnimation, accumulatedDuration)
        accumulatedDuration += duration * 2
    }
    rectsTimeline.play(accumulatedDuration)
}


