import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {LocalProgressComponent} from "../../global/globalProgress/localProgressComponent";
import {ViewerDataTemplateComponent} from "../../../Template/viewer/Template/component/ViewerDataTemplateComponent";
import {Base3dViewerManager} from "../../../Template/viewer/Template/component/Base3dViewerManager";
import {dataService} from "../../../Template/viewer/api/dataService";
import {SaaSCavingViewerManager} from "../BminingSaasCaving/SaaSCavingViewerManager";
import {ColumnsTechnicalNotesEntity} from "../../../Template/viewer/Template/entities/ColumnsTechnicalNotesEntity";
import {IApiDataTechnicalNotesColumnsEntity} from "../../../Template/viewer/api/IApiDataTechnicalNotesColumnsEntity";
import {IApiDataTechnicalNotesPolylineEntity} from "../../../Template/viewer/api/IApiDataTechnicalNotesPolylineEntity";
import {PolylinesTechnicalNotesEntity} from "../../../Template/viewer/Template/entities/PolylinesTechnicalNotesEntity";
import {SolidTechnicalNotesEntity} from "../../../Template/viewer/Template/entities/SolidTechnicalNotesEntity";
import {IApiDataTechnicalNotesSolidEntity} from "../../../Template/viewer/api/IApiDataTechnicalNotesSolidEntity";
import {TopographyEntity} from "../../../Template/viewer/Template/entities/TopographyEntity";
import {FormControlLabel, Stack, Switch} from "@mui/material";
import {margin, spacing} from "../../../Resources/styles";
import {Material} from "three";

export const TechnicalNotesAlternativePowerBiComponent = () => {
    const [manager] = useState(() => new Base3dViewerManager())
    const [isLoadingScene, setIsLoadingScene] = useState(true)


    useEffect(() => {
    }, [])

    const onInitCallback = async () => {
        dataService.registerEntityType("Technical Note Scenery Columns Caving", (data, currentManager) => {
            const technicalNotesEntity = new ColumnsTechnicalNotesEntity(data as IApiDataTechnicalNotesColumnsEntity, currentManager as SaaSCavingViewerManager);
            return Promise.resolve(technicalNotesEntity);
        })

        dataService.registerEntityType("Technical Note Scenery Polyline Caving", (data, currentManager) => {
            const technicalNotesEntity = new PolylinesTechnicalNotesEntity(data as IApiDataTechnicalNotesPolylineEntity, currentManager as SaaSCavingViewerManager);
            return Promise.resolve(technicalNotesEntity);
        })

        dataService.registerEntityType("Technical Note Scenery Solid Caving", (data, currentManager) => {
            const technicalNotesEntity = new SolidTechnicalNotesEntity(data as IApiDataTechnicalNotesSolidEntity, currentManager as SaaSCavingViewerManager);
            return Promise.resolve(technicalNotesEntity);
        })


        const url = "https://storage.googleapis.com/global-info/global-info/asset/three/Technical Notes/Scenery 01";
        const scene = await dataService.readSceneryByUrl(url, manager)
        for (const sceneEntity of scene.entities) {
            if (sceneEntity instanceof TopographyEntity) {
                sceneEntity.object.translateZ(2)
                sceneEntity.name = "Topografía LoA Alternativa 1";
                sceneEntity.hasTooltip = false
                const material = (sceneEntity.object.material as Material)
                material.transparent = true
                material.opacity = 0.7
            }
            if (sceneEntity instanceof ColumnsTechnicalNotesEntity) {
                sceneEntity.object.visible = false
                sceneEntity.hasTooltip = false
            }

            if (sceneEntity instanceof SolidTechnicalNotesEntity) {
                sceneEntity.object.visible = true
                sceneEntity.hasTooltip = true
            }
            manager.addEntity(sceneEntity)
        }
        setIsLoadingScene(false)
    }


    const setVisibleTopography = (visible: boolean) => {
        const topography = manager.getEntities().find(value => value instanceof TopographyEntity)!
        topography.object.visible = visible
    }
    const setColumnsVisible = (visible: boolean) => {
        const columns = manager.getEntities().filter(value => value instanceof ColumnsTechnicalNotesEntity)!
        for (const column of columns) {
            column.object.visible = visible
            column.hasTooltip = visible
        }

        const solids = manager.getEntities().filter(value => value instanceof SolidTechnicalNotesEntity)!
        for (const solid of solids) {
            solid.object.visible = !visible
            solid.hasTooltip = !visible
        }
    }


    return <Box component="div" height='100vh' width='100hw'>
        {isLoadingScene && <LocalProgressComponent/>}
        <ViewerDataTemplateComponent manager={manager} viewerInitCallback={onInitCallback}/>
        <Box m={margin} component="div" style={{position: "fixed", right: "0px", bottom: "0px"}}>

            <Stack direction='row-reverse'>
                <FormControlLabel control={<Switch defaultChecked size="small" onChange={(_, checked) => {
                    setVisibleTopography(checked)
                }}/>} label="Topografía"/>

                <FormControlLabel control={<Switch size="small" onChange={(_, checked) => {
                    setColumnsVisible(checked)
                }}/>} label="Columnas"/>
            </Stack>
        </Box>
    </Box>
}