import {Base3dViewerManager} from "../Template/component/Base3dViewerManager";
import {IApiDataSceneSpecification} from "./IApiDataSceneSpecification";
import {IApiDataPolylineEntity} from "./IApiDataPolylineEntity";
import {PolylineEntity} from "../Template/entities/PolylineEntity";
import {GLTFLoader} from "three-stdlib";
import {IApiDataTopographyEntity} from "./IApiDataTopographyEntity";
import {TopographyEntity} from "../Template/entities/TopographyEntity";
import {MeshPhongMaterial, Object3D, SkinnedMesh} from "three";
import {Dictionary} from "../../../api/Dictionary";
import {IApiDataEntity} from "./IApiDataEntity";
import {BaseEntity} from "../Template/entities/BaseEntity";
import {IScene} from "./IScene";
import {IApiDataDemoDroneEntity} from "./IApiDataDemoDroneEntity";
import {DemoDroneEntity} from "../Template/entities/DemoDroneEntity";
import {apiUtils} from "../../../api/ApiConfig";
import {IApiDataConfiguration} from "./IApiDataConfiguration";
import JSZip from "jszip";


interface IViewerOptions {
    loadViewBox?: boolean

}

class DataService {

    private readonly entityTypes: Dictionary<(data: IApiDataEntity, manager: Base3dViewerManager) => Promise<BaseEntity<Object3D>>>
    private readonly configurationTypes: Dictionary<(data: IApiDataConfiguration, manager: Base3dViewerManager) => Promise<void>>

    constructor() {
        this.entityTypes = {}
        this.configurationTypes = {}

        this.entityTypes["Polyline"] = (data, manager) => {
            const dataPolyline = data as IApiDataPolylineEntity
            const polyline = new PolylineEntity(dataPolyline, manager)
            return Promise.resolve(polyline)
        }

        this.entityTypes["Topography"] = (data, manager) => {
            const dataTopography = data as IApiDataTopographyEntity
            const topography = new TopographyEntity(dataTopography, manager)
            return Promise.resolve(topography)
        }

        this.entityTypes["Demo Drone"] = async (data, manager) => {
            const dataDrone = data as IApiDataDemoDroneEntity


            const loader = new GLTFLoader()

            const droneArray = apiUtils.base64ToArrayBuffer(dataDrone.gltf)
            const gltf = await loader.parseAsync(droneArray!, "")

            gltf.scene.traverse(object => {
                if ((object as SkinnedMesh).isSkinnedMesh) {
                    const skinnedMesh = object as SkinnedMesh
                    skinnedMesh.material = new MeshPhongMaterial({
                        color: Math.random() > 0.5 ? 'rgb(0,145,164)' : 'rgb(183,183,183)'
                    })
                }
            })

            const demoDroneEntity = new DemoDroneEntity(gltf, dataDrone, manager)
            return Promise.resolve(demoDroneEntity)
        }
    }

    registerEntityType(entityType: string, method: (data: IApiDataEntity, manager: Base3dViewerManager) => Promise<BaseEntity<Object3D>>) {
        if (this.entityTypes[entityType]) {
            return
        }
        this.entityTypes[entityType] = method
    }

    registerConfigurationType(entityType: string, method: (data: IApiDataConfiguration, manager: Base3dViewerManager) => Promise<void>) {
        if (this.configurationTypes[entityType]) {
            return
        }
        this.configurationTypes[entityType] = method
    }


    async readSceneryByUrl(url: string, manager: Base3dViewerManager, options?: IViewerOptions) {
        const specificationName = "scene.spec"
        const response = await fetch(url, {
            method: "GET"
        })

        const entities: BaseEntity<Object3D>[] = []
        const configurations: IApiDataConfiguration[] = []

        const blob = await response.blob()


        // -- Specification --
        const content = await JSZip.loadAsync(blob)
        const specification = JSON.parse(await content.files[specificationName].async('text')) as IApiDataSceneSpecification
        if (options) {
            if (options.loadViewBox)
                manager.setViewBoxFromBoundingBox(specification.boundingBox)
        } else {
            manager.setViewBoxFromBoundingBox(specification.boundingBox)

        }


        if (!specification.configurations)
            specification.configurations = []
        // Configurations
        for (const configurationInfo of specification.configurations) {
            const configuration = JSON.parse(await content.files[configurationInfo.configurationPath].async('text')) as IApiDataConfiguration
            if (this.configurationTypes[configuration.type])
                await this.configurationTypes[configuration.type](configuration, manager)
            configurations.push(configuration)
        }
        // Entities
        for (const entityInfo of specification.entities) {

            if (this.entityTypes[entityInfo.type]) {
                let data = JSON.parse(await content.files[entityInfo.entityPath].async('text')) as IApiDataEntity
                if (entityInfo.type != data.type)
                    continue
                const entity = await this.entityTypes[entityInfo.type](data, manager)
                entities.push(entity)
            }
        }

        return {
            entities: entities,
            boundingBox: specification.boundingBox,
            configurations: configurations
        }
    }

}

export const dataService = new DataService()


